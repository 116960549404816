<template>
    <div class="location-selection-popup">
        <div class="modal-mask">
            <div class="modal-wrapper">
                <div class="modal-container row">
                    <div class="right-col col-md-12">
                        <div id="mc_embed_signup">
                            <form
                                action="https://photoppz.us13.list-manage.com/subscribe/post?u=5bfdf2a480a1a20c1a952bf1a&amp;id=317ebbda35&amp;f_id=002c7de9f0"
                                method="post"
                                id="mc-embedded-subscribe-form"
                                name="mc-embedded-subscribe-form"
                                class="validate"
                                target="_blank"
                            >
                                <div id="mc_embed_signup_scroll">
                                    <h2>Subscribe</h2>
                                    <div class="indicates-required">
                                        <span class="asterisk">*</span> indicates required
                                    </div>
                                    <div class="mc-field-group">
                                        <label for="mce-EMAIL">Email Address <span class="asterisk">*</span></label>
                                        <input
                                            type="email"
                                            name="EMAIL"
                                            class="required email"
                                            id="mce-EMAIL"
                                            required=""
                                            v-model="email"
                                            @change="onEmailChange"
                                        />
                                    </div>
                                    <div id="mce-responses" class="clear foot">
                                        <div class="response" id="mce-error-response" style="display: none"></div>
                                        <div class="response" id="mce-success-response" style="display: none"></div>
                                    </div>
                                    <div aria-hidden="true" style="position: absolute; left: -5000px">
                                        /* real people should not fill this in and expect good things - do not remove
                                        this or risk form bot signups */
                                        <input
                                            type="text"
                                            name="b_148b53f8d7a9928b59661e37f_3b6bb1510a"
                                            tabindex="-1"
                                            value=""
                                        />
                                    </div>
                                    <div class="optionalParent">
                                        <div class="clear foot">
                                            <input
                                                type="submit"
                                                name="subscribe"
                                                id="mc-embedded-subscribe"
                                                class="button"
                                                value="Subscribe"
                                                @click="this.isValidEmail ? $emit('close') : null"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <button class="modal-default-button" @click="$emit('close')">
                            x
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "MailChimpPopup",
    data: () => ({
        email: "",
        isValidEmail: false
    }),
    methods: {
        validateEmail(email) {
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailPattern.test(email);
        },
        onEmailChange() {
            this.isValidEmail = this.validateEmail(this.email);
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;
}

.modal-wrapper {
    display: table-cell;
    vertical-align: middle;
}

.modal-container {
    width: 100%;
    max-width: 500px;
    margin: 0px auto;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
}

.modal-default-button {
    float: right;
}

.modal-enter-from,
.modal-leave-to {
    opacity: 0;
}

.modal-enter-active .modal-container,
.modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}
</style>